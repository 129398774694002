<template>
  <div>
    <b-modal
      id="modal-rejection-details-show"
      ref="modal"
      v-model="open"
      size="ov-custom"
      no-close-on-backdrop
      hide-footer
      centered
      title="Rejection Details"
    >
      <b-overlay
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-table
          :fields="fields"
          :items="rows"
          hover
          responsive
          striped
          show-empty
          @sort-changed="sortChanged"
        >
          <template #empty>
            <TableDataFetching
              :rows="rows"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(date_time)="data">
            <div class="font-weight-bold">
              {{ __dateTimeFormatter(data.value) }}
            </div>
            <small>{{ data.item.date_time_ago }}</small>
          </template>
          <template #cell(user)="data">
            <div class="font-weight-bold">
              {{ data.value.first_name }} {{ data.value.last_name }}
            </div>
            <small>{{ data.item.user_role }}</small>
          </template>
          <template #cell(reversal_comment)="data">
            <div class="font-weight-bold">
              {{ data.value }}
            </div>
          </template>
          <template #cell(data)="data">
            <div>
              <b>Payment Method :&nbsp;</b><span>{{ data.value.payment_method || '---' }}</span>
            </div>
            <div>
              <b>Transaction Number :&nbsp;</b><span>{{ data.value.transaction_number || '---' }}</span>
            </div>
            <div>
              <b>Payment Date :&nbsp;</b><span>{{ data.value.deposit_date.substring(0,10) || '---' }}</span>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    dataLoading: true,
    id: null,
    rows: [],
    fields: [
      {
        key: 'date_time',
        label: 'Rejected Date',
        sortable: true,
      }, {
        key: 'user',
        label: 'Rejected By',
        sortable: true,
        tdClass: 'text-left',
      },
      {
        key: 'reversal_comment',
        label: 'Remark',
        sortable: false,
        tdClass: 'text-left',
      },
      {
        key: 'data',
        label: 'Rejected Deposit Details',
        sortable: false,
      },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BTable,
  },
  mixins: [ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      await this.fetchRejectionDetails()
    },
    async fetchRejectionDetails() {
      try {
        const { data } = await FinanceRepository.getInvoiceRejectionDetails(this.id)
        this.rows = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
  },
}
</script>
<style scoped>
</style>
