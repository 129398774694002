<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <!-- <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Warehouse"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Invoice No"
              label-for="v-invoice_no"
            >
              <b-form-input
                id="v-invoice_no"
                v-model="filters['invoice_no']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Invoice Date"
              label-for="v-ref-no"
            >
              <div class="position-relative">
                <flat-pickr
                  id="v-ref-no"
                  v-model="filters['created_at']"
                  :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                  class="form-control"
                  placeholder="Select Date"
                />
                <div
                  v-if="filters['created_at']"
                  class="flatpickr-cancel"
                  @click="clearDate('created_at')"
                >
                  <feather-icon icon="XIcon" />
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Finance Status"
              label-for="Finance_status"
            >
              <v-select
                v-model="filters['status']"
                :options="financeStatus"
                :reduce="option => option.key"
                label="name"
                placeholder="Select Status"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div
          class="m-2"
        >
          <b-row>
            <b-col
              md="6"
              class="d-flex flex-wrap justify-content-sm-start align-items-center"
            >
              <div class="font-weight-bolder">
                {{ `${depositSelected.length} record${depositSelected.length === 1 ? '':'s'}` }} selected
              </div>
            </b-col>
            <b-col
              md="6"
              class="d-flex flex-wrap justify-content-sm-end align-items-center"
            >
              <div class="d-flex flex-wrap justify-content-end">
                <div class="mb-1 mobile-width-fix mt-1 mt-md-0">
                  <b-button
                    v-can="'finance-approve'"
                    :disabled="depositSelected.length === 0"
                    variant="info"
                    class="mobile-width-fix mr-1"
                    @click="onClickApprove()"
                  >
                    Approve
                  </b-button>
                </div>
                <div class="mb-1 mobile-width-fix">
                  <b-button
                    v-can="'finance-reject'"
                    :disabled="depositSelected.length === 0"
                    variant="danger"
                    class="mobile-width-fix"
                    @click="onClickReject()"
                  >
                    Reject
                  </b-button>
                </div>
                <div class="mb-1 ml-1">
                  <TableButtons
                    :items="items"
                    :json_fieldz="json_fields"
                    :name="`MerchantInvoices - Page ${page}.xls`"
                    :bulkname="`MerchantInvoices.xls`"
                    :fetch="fetchDataForMerchantNoPagination"
                    @refresh="onClickRefresh"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            :per-page="perPage"
            :current-page="Page"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            hover
            responsive
            striped
            show-empty
            sticky-header="100vh"
            class="hide-vertical-scroll"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #head(id)>
              <div class="d-flex align-items-start">
                <div>
                  <b-form-checkbox
                    id="selectAllCheckbox"
                    v-model="selectAllCheckbox"
                    name="selectAllCheckbox"
                    @change="onClickSelectAllCheckbox()"
                  />
                </div>
              </div>
            </template>
            <template #cell(id)="data">
              <div class="d-flex align-items-start">
                <div>
                  <div class="font-weight-bolder">
                    <b-form-checkbox
                      v-model="depositSelected"
                      :value="data.item.id"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(invoice_no)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  :text="avatarText(data.item.invoice_no)"
                  size="32"
                  variant="light-primary"
                />
                <div class="ml-1">
                  <div
                    class="font-weight-bolder text-primary"
                    @click="onClickInvoice(data.item.id)"
                  >
                    {{ data.value }}
                  </div>
                  <div class="font-small-2">
                    {{ __dateTimeFormatter(data.item.created_at) }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(merchant_business)="data">
              <span>{{ data.item.merchant_business || '---' }}</span>
            </template>
            <template #cell(commission_rate)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(collected_cod)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(commission_amount)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(delivery_charge)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(invoice_amount)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(total_tax_applied)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(invoice_amount_after_taxes)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(approved_date)="data">
              {{ __dateTimeFormatter(data.value) || '---' }}
            </template>
            <template #cell(status.key)="data">
              <finance-status-badge :status-key="data.value" />
              <div
                v-if="data.item.is_reversed_deposit"
                class="mt-1 d-flex justify-content-center"
              >
                <b-tooltip
                  :target="data.item.id.toString()"
                  triggers="hover"
                  variant="danger"
                >
                  Rejected Once
                </b-tooltip>
                <b-avatar
                  :id="data.item.id.toString()"
                  size="20"
                  style="background-color: #ffb5b5"
                >
                  <feather-icon
                    icon="ChevronsLeftIcon"
                    class="text-body align-middle"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                :class="items.length !== 1 ? 'dropdown-no-scroll':''"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="{ name: 'merchant-finance-invoice', params: { id: data.item.id } }"
                >
                  <feather-icon icon="BookIcon" />
                  View Invoice
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.receipt_url"
                  :href="`${bucketUrl}${data.item.receipt_url}`"
                  target="_blank"
                >
                  <feather-icon icon="EyeIcon" />
                  View Payment Slip
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.is_reversed_deposit"
                  @click="onClickShowRejectionReasons(data.item.id)"
                >
                  <feather-icon icon="BookIcon" />
                  Rejection Reasons
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'invoice-orders', params: { id: data.item.id } }"
                >
                  <feather-icon icon="ArchiveIcon" />
                  View Orders
                </b-dropdown-item>
                <b-dropdown-item
                  @click="onClickOpenRequestPaymentModal(data.item.id)"
                >
                  <feather-icon icon="PocketIcon" />
                  Request Payment
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              class="d-flex align-items-center justify-content-sm-start"
              md="3"
            >
              <label>Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-1 width-100 mr-1"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              class="d-flex align-items-center justify-content-sm-end"
              md="9"
            >
              <span class="text-muted pagination-text mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
      <approve-action ref="approve_action_modal" />
      <reject-action ref="reject_action_modal" />
      <rejection-details ref="rejection_detail_modal" />
      <request-payment ref="merchant_payment_request_modal" />
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAvatar,
  VBModal,
  BOverlay,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { bucketUrl } from '@/constants/config'
import { avatarText } from '@core/utils/filter'
import Filter from '@core/mixins/filter'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import ApproveAction from '@/components/FinanceModuleForms/ActionMerchantForms/ApproveAction.vue'
import RejectAction from '@/components/FinanceModuleForms/ActionMerchantForms/RejectAction.vue'
// eslint-disable-next-line import/extensions
import RejectionDetails from '@/components/FinanceModuleForms/InfoModals/RejectionDetailModal.vue'
import requestPayment from '@/components/RequestPayment/requestPayment.vue'
import flatPickr from 'vue-flatpickr-component'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const FinanceRepository = RepositoryFactory.get('finance')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    requestPayment,
    ApproveAction,
    RejectAction,
    RejectionDetails,
    vSelect,
    BTooltip,
    BOverlay,
    BCard,
    BTable,
    BFormCheckbox,
    BRow,
    BAvatar,
    BCol,
    BFormGroup,
    BPagination,
    BButton,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BCardActions,
    FinanceStatusBadge,
    flatPickr,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      bucketUrl,
      deselect: true,
      depositSelected: [],
      finance: '',
      filters: {},
      financeStatus: [],
      sort: '',
      avatarText,
      perPage: 20,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      page: 1,
      meta: {},
      dir: false,
      dataLoading: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'ref_no',
          label: 'REF',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'name',
          label: 'BRN',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'invoice_no',
          label: 'Invoice No',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'merchant.name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'merchant_business',
          label: 'Merchant Business',
          sortable: true,
        },
        {
          key: 'commission_rate',
          label: 'Commission Rate',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'collected_cod',
          label: 'Collected COD',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'commission_amount',
          label: 'Commission Amount',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'delivery_charge',
          label: 'Delivery Charge',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'invoice_amount',
          label: 'Invoice Amount',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'total_tax_applied',
          label: 'Total Tax Amount',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'invoice_amount_after_taxes',
          label: 'After Tax Amount',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'approved_date',
          label: 'Approved Date',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'action', label: 'Action', sortable: false },
      ],
      json_fields: {
        'Invoice Number': 'invoice_no',
        'Merchant Name': {
          field: 'merchant.name',
          callback: value => `"${value}"`,
        },
        'Merchant Business': {
          field: 'merchant_business',
          callback: value => `"${value}"`,
        },
        'Commission Rate': 'commission_rate',
        'Collected COD': 'collected_cod',
        'Commission Amount': 'commission_amount',
        'Delivery Charge': 'delivery_charge',
        'Invoice Amount': 'invoice_amount',
        'Total Tax Amount': 'total_tax_applied',
        'After Tax Amount': 'invoice_amount_after_taxes',
        'Approved Date': {
          field: 'approved_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Depositable Amount': 'depositable_amount',
        Status: 'status.name',
      },
      items: [],
      selectAllCheckbox: false,
      // end
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForMerchant()
      },
    },
    deep: true,
    page() {
      this.fetchDataForMerchant()
    },
    perPage() {
      this.fetchDataForMerchant()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchDataForMerchant()
    this.financeStatus = FinanceStatusBadge.data().statusList
  },
  methods: {
    async fetchDataForMerchant() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await FinanceRepository.getMerchantFinanceDepositList(this.page, this.filterQuery, this.perPage))
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchDataForMerchantNoPagination() {
      try {
        const { data } = (await FinanceRepository.getMerchantFinanceDepositListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDataForMerchant()
    },
    applyFilters() {
      this.fetchDataForMerchant()
      this.onClickDeselectAll()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchDataForMerchant()
      })
    },
    onClickOpenRequestPaymentModal(id) {
      this.$refs.merchant_payment_request_modal.openModal(id)
    },
    onClickSelectAll() {
      this.depositSelected = this.items.map(n => n.id)
    },
    onClickDeselectAll() {
      this.depositSelected = []
    },
    onClickApprove() {
      this.$refs.approve_action_modal.openModal(this.depositSelected)
    },
    onClickReject() {
      this.$refs.reject_action_modal.openModal(this.depositSelected)
    },
    onClickShowRejectionReasons(id) {
      this.$refs.rejection_detail_modal.openModal(id)
    },
    onClickRefresh() {
      this.onClickDeselectAll()
      this.fetchDataForMerchant()
    },
    onClickInvoice(invoice) {
      this.$router.push({
        name: 'merchant-finance-invoice',
        params: {
          id: invoice,
        },
      })
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        this.depositSelected = this.items.map(n => n.id)
      } else {
        this.depositSelected = []
      }
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@media (max-width: 767px) {
  .table-responsive {
    position: sticky;
    top: 0;
  }
}
</style>
