<template>
  <b-modal
    id="modal-merchant-payment-request"
    ref="modal"
    v-model="open"
    size="md"
    no-close-on-backdrop
    hide-footer
    title="ADD PAYMENT REQUEST"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantUserForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickRequest)"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Add Remark"
                label-for="h-remark"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="remark"
                  rules="required"
                >
                  <b-form-input
                    id="remark"
                    v-model="remark"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter remark"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Request
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    modalLoading: false,
    invoiceID: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(invoiceID) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.invoiceID = invoiceID
    },

    async onClickRequest() {
      this.modalLoading = true
      try {
        const payload = {
          remark: this.remark,
        }
        const res = await FinanceRepository.makeInvoicePaymentRequest(this.invoiceID, payload)
        if (res.status === 201) {
          this.showSuccessMessage('Payment Request Made Successfully')
          this.open = false
          this.remark = ''
        } else {
          this.showErrorMessage('Could not make payment request')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
</style>
