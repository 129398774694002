<template>
  <b-modal
    id="approve-action-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="CONFIRM APPROVAL"
  >
    <b-row>
      <b-col md="3" />
      <b-col md="5">
        <b-form-group
          label-for="vi-remark"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="TerminalIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-remark"
              v-model="form.remark"
              placeholder="Enter Remark"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-button
          v-can="'rider-finance-approve'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="makeApprove"
        >
          <feather-icon
            icon="CheckCircleIcon"
            class="mr-50"
          />
          <span class="align-middle">Approve</span>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BButton, BInputGroup, BInputGroupPrepend, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    form: {},
    depositSelected: [],
    financeType: '',
  }
}

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(selectedDeposits, financeType) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.depositSelected = selectedDeposits
      this.financeType = financeType
    },
    makeApprove() {
      if (this.depositSelected.length === 0 || this.depositSelected === []) {
        this.showErrorMessage('Please Select Deposits First')
      } else {
        const data = {
          merchant_invoice_ids: this.depositSelected,
          comment: this.form.remark,
          status_key: 'f_key_3',
        }
        this.handleSubmit(data)
      }
    },
    async handleSubmit(data) {
      try {
        const res = await FinanceRepository.approveStatusMerchantFinanceDeposit(data)
        if (res.status === 202) {
          this.showSuccessMessage('Data Added Successfully')
          await this.$router.go(0)
        } else {
          this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
          await this.$router.push('/my-invoices')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
</style>
